var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row center main-bg"},[_c('loading',{attrs:{"active":_vm.isLoading,"color":"#f8a229","loader":"dots","width":_vm.loadingSize,"height":_vm.loadingSize,"is-full-page":_vm.loadingFullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-dialog',{attrs:{"max-width":"500px","origin":"top right"},model:{value:(_vm.scaleReconnectDialog),callback:function ($$v) {_vm.scaleReconnectDialog=$$v},expression:"scaleReconnectDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Scale Connection ")]),_c('v-card-text',{staticClass:"py-0"},[_vm._v(" Connection to the scale is closed. Do you want to reconnect? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.scaleReconnectDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.$webSerialApi.startConnection();
            _vm.scaleReconnectDialog = false;}}},[_vm._v(" Yes ")])],1)],1)],1),_c('div',{staticClass:"product-details white-bg"},[(_vm.singleItem.itemPic)?_c('img',{staticClass:"product-img",attrs:{"src":_vm.$url + _vm.singleItem.itemPic,"width":"auto","height":"200","alt":""}}):_vm._e(),(!_vm.singleItem.itemPic)?_c('v-img',{staticClass:"no-product-img white--text align-end",attrs:{"gradient":"rgba(248,162,41,.5), rgba(0,0,0,.2)","width":"150","height":"200"}},[_c('div',{staticStyle:{"margin-top":"-50px","text-align":"center"}},[_c('v-icon',{staticClass:"text-h1",attrs:{"color":"white"}},[_vm._v(" mdi-image ")])],1)]):_vm._e(),_c('div',{staticClass:"product-name-price"},[_c('h1',{staticClass:"product-title"},[_vm._v(" "+_vm._s(_vm.singleItem.productName)+" ")]),(
          _vm.singleItem.retailPrice &&
          (!(_vm.singleItem.productSizes && _vm.singleItem.productSizes.length > 0) ||
            _vm.itemSize)
        )?_c('h1',{staticClass:"product-price"},[_c('span',[_vm._v(" $ "+_vm._s(_vm.formatPrice(_vm.productPrice)))])]):_vm._e()]),(_vm.$store.getters.getSubDepartment === 'Misc')?_c('div',{staticClass:"mb-n5"},[_c('v-text-field',{staticClass:"text-h4",attrs:{"label":"Customized Description","solo":""},model:{value:(_vm.customizedDescription),callback:function ($$v) {_vm.customizedDescription=$$v},expression:"customizedDescription"}})],1):_vm._e(),_c('div',{staticClass:"mb-n12"},[_c('v-text-field',{staticClass:"text-h4",attrs:{"label":"Customized Price","solo":""},model:{value:(_vm.customizedPrice),callback:function ($$v) {_vm.customizedPrice=$$v},expression:"customizedPrice"}})],1),_c('div',{staticClass:"product-qty-price"},[_c('h2',{staticClass:"product-qty"},[_vm._v("QTY:")]),_c('v-col',{staticClass:"mt-8 ml-2"},[_c('v-text-field',{ref:"productQty",staticClass:"text-h4",staticStyle:{"width":"50%"},attrs:{"dense":"","label":"CHANGE","solo":""},model:{value:(_vm.productQty),callback:function ($$v) {_vm.productQty=$$v},expression:"productQty"}})],1),(
          !_vm.productCost &&
          (!(_vm.singleItem.productSizes && _vm.singleItem.productSizes.length > 0) ||
            _vm.itemSize)
        )?_c('h2',{staticClass:"total-price"},[_vm._v(" Cost: $"+_vm._s(_vm.formatPrice(_vm.productPrice))+" ")]):_vm._e(),(_vm.productCost)?_c('h2',{staticClass:"total-price"},[_vm._v(" Cost: $"+_vm._s(_vm.formatPrice(_vm.productCost))+" ")]):_vm._e()],1),_c('div',{staticClass:"btn-group"},[_c('v-btn',{staticClass:"btn text-h5",staticStyle:{"border-radius":"5px"},attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addToCart()}}},[_vm._v(" ADD TO CART ")]),_c('v-btn',{staticClass:"btn text-h5",staticStyle:{"border-radius":"5px"},attrs:{"color":"secondary2","dark":""},on:{"click":function($event){return _vm.cancelOrder()}}},[_vm._v(" CANCEL ")])],1),_c('div',{staticClass:"bottom-product-qty-price"},[_c('h2',{staticClass:"product-qty"},[_vm._v("QTY:")]),_c('v-col',{staticClass:"mt-8 ml-2"},[_c('v-text-field',{ref:"productQty",staticClass:"text-h4",staticStyle:{"width":"50%"},attrs:{"dense":"","label":"CHANGE","solo":""},model:{value:(_vm.productQty),callback:function ($$v) {_vm.productQty=$$v},expression:"productQty"}})],1),(
          !_vm.productCost &&
          (!(_vm.singleItem.productSizes && _vm.singleItem.productSizes.length > 0) ||
            _vm.itemSize)
        )?_c('h2',{staticClass:"total-price"},[_vm._v(" Total: $"+_vm._s(_vm.formatPrice(_vm.productPrice))+" ")]):_vm._e(),(_vm.productCost)?_c('h2',{staticClass:"total-price"},[_vm._v(" Total: $"+_vm._s(_vm.formatPrice(_vm.productCost))+" ")]):_vm._e()],1),_c('div',{staticClass:"bottom-btn-group"},[_c('v-btn',{staticClass:"btn text-h5",staticStyle:{"border-radius":"5px"},attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addToCart()}}},[_vm._v(" ADD TO CART ")]),_c('v-btn',{staticClass:"btn text-h5",staticStyle:{"border-radius":"5px","margin-left":"3%"},attrs:{"color":"secondary2","dark":""},on:{"click":function($event){return _vm.cancelOrder()}}},[_vm._v(" CANCEL ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }